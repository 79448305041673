import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Weight`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-base--base'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Font family`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--font-family'
    }]} height="260" mdxType="StorybookEmbed" />
    <h2>{`Font shorthand`}</h2>
    <p>{`Use the `}<inlineCode parentName="p">{`font`}</inlineCode>{` declaration to set all font properties at once (`}<inlineCode parentName="p">{`font-weight`}</inlineCode>{`, `}<inlineCode parentName="p">{`font-size`}</inlineCode>{`, `}<inlineCode parentName="p">{`line-height`}</inlineCode>{`, and `}<inlineCode parentName="p">{`font-family`}</inlineCode>{`).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`  font: var(--text-display-shorthand);
`}</code></pre>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--font-shorthand'
    }]} height="940" mdxType="StorybookEmbed" />
    <h2>{`Display`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--display'
    }]} height="300" mdxType="StorybookEmbed" />
    <h2>{`Title large`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--title-large'
    }]} height="300" mdxType="StorybookEmbed" />
    <h2>{`Title medium`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--title-medium'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Title small`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--title-small'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Subtitle`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--subtitle'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Body large`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--body-large'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Body medium`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--body-medium'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Body small`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--body-small'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Caption`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--caption'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Code block`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--code-block'
    }]} height="280" mdxType="StorybookEmbed" />
    <h2>{`Inline code block`}</h2>
    <StorybookEmbed baseUrl="https://primer.style/primitives/storybook" stories={[{
      id: 'typography-functional--inline-code-block'
    }]} height="280" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      